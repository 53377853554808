import Container from 'react-bootstrap/Container';

function App() {
  return (
    <Container>
      <h1>hi!</h1>
      <p>if you think something else belongs here, you can email <a href="mailto:hi@bansko.io">hi@bansko.io</a>.</p>
    </Container>
  );
}

export default App;
